<template>
  <span
    class="roundel ui-w-[64px] ui-h-[64px] ui-flex ui-flex-col ui-items-center ui-justify-center ui-font-bold ui-uppercase ui-text-center"
    :class="variantClasses[variant]"
  >
    <span v-if="variant === 'new'">New</span>
    <span v-if="variant === 'discount'">00%</span>
    <span v-if="variant === 'discount'" class="ui-text-[18px]">Off</span>
    <Icon v-if="variant === 'eco'" name="sustainable-option" :size="32" />
  </span>
</template>

<script lang="ts" setup>
import Icon from "../Icon/Icon.vue";
import type { RoundelProps } from "./types";

withDefaults(defineProps<RoundelProps>(), {
  variant: "new",
});

const variantClasses = {
  ["new"]: "ui-bg-mkm-yellow-default ui-text-mkm-blue-default ui-text-[18px]",
  ["discount"]:
    "ui-bg-mkm-blue-default ui-text-mkm-yellow-default ui-text-[21px] ui-leading-[20px] ui-pt-3xs",
  ["eco"]: "ui-bg-green-dark ui-text-white",
};
</script>

<style scoped>
.roundel {
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%, 0 35%);
}
</style>
